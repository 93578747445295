<template>
    <div>
        <v-card flat id="product_datatable">
            <v-container fluid class="ma-0 pa-0 q_leads_1" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="12" sm="6" id="q-pending-policy-step-3" class="mb-0 pb-0 px-7">
                        <p class="q-display-3  pb-0 pt-0 mt-0 mb-0 white--text">Carrier Products</p>
                        <p class="pt-0 mt-0 ">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filter Products
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="12" sm="6" class="pr-8 pl-7 pr-3 mb-0 pb-0 d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-text-field dense dark v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-row class="d-flex justify-start" v-if="toggleFilters || showFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys">
                    <v-col cols="6" sm="4" md="4">
                        <v-select label="Other" :items="other_filter" v-model="other_filter_selections" multiple @change="otherFiltersUpdated"></v-select>
                    </v-col>
                </q-filters>
            </v-row>

            <QExpandableDataTable
                :fixed-header="true" 
                :mobile-breakpoint="0" 
                :search="search" 
                :headers="the_headers" 
                :items="all_data" 
                item-key="ID" 
                class="elevation-0" 
                :footer-props="{ itemsPerPageOptions: [10,25,50,100] }" 
                :loading="loading" 
                :options.sync="options"
                :visible-headers="visibleHeaders"
                style="inset -2px 4px 8px rgb(0 0 0 / 10%) !important"
                color="q_leads_1"
            >
                <template v-slot:[`item.carrier.CarrierName`]="{ item }">
                    <span class="nowrap" @click="clicked(item.carrier.CarrierCMSURL)">
                        {{item.carrier.CarrierName}}
                    </span>
                </template>
                <template v-slot:[`item.eAppInd`]="{ item }">
                    <span class="nowrap">
                        <v-icon small left v-if="item.eAppInd == '1' && item.carrier.CarrierURL" color="success" @click="clicked(item.carrier.CarrierEAppURL)">fas fa-external-link-alt</v-icon>
                    </span>
                    <span class="nowrap">
                        <v-icon small left v-if="item.eAppInd == '0' && item.carrier.CarrierURL" color="red" @click="clicked(item.carrier.CarrierEAppURL)">fas fa-times</v-icon>
                    </span>
                </template>

                <template v-for="itemName in ['PaperAppInd', 'InstantDecisionInd', 'FlexiblePremiumPossibleInd', 'NY', 'CA']" v-slot:[`item.${itemName}`]="{ item }">
                    <span class="nowrap" :key='itemName'>
                        <v-icon small left v-if="item[itemName] == '1'" color="success">fas fa-check</v-icon>
                        <v-icon small left v-if="item[itemName] == '0'" color="red">fas fa-times</v-icon>
                    </span>
                </template>

                <template v-slot:[`item.ProductGuide`]="{ item }">
                    <span v-if="item.ProductGuide" class="nowrap">
                        <a @click="clicked(item.ProductGuide)">
                            <v-icon small left color="success">fas fa-external-link-alt</v-icon>
                        </a>
                    </span>
                    <span class="nowrap" v-else>
                        <v-icon small left color="red">fas fa-times</v-icon>
                    </span>
                </template>
            </QExpandableDataTable>
        </v-card>
    </div>
</template>
<script>
import QGenericDataTableMixin from "./QGenericDataTableMixin"
import QExpandableDataTable from "./QExpandableDataTable.vue"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QAgentProductDataTable",
    props: ['carrier_id'],
    mixins: [QGenericDataTableMixin],
    data() {
        return {
            entity: 'product',
            //use this to "preset" a filter
            filters: {
                Carrier: this.carrier_id ? parseInt(this.carrier_id) : '-All-'
            }, //these are the filters the user has set or you can preset them
            options: {
                'sortBy': [], //used to set the default sorting column
                'sortDesc': [true],
                'itemsPerPage': 25
            },
            expanded: [],
            available_product_filters: null,
            the_data: [], //this will hold the data fromt he server now that we aren't using vuex anymore.
            showFilters: this.carrier_id ? true : false,
            other_filter: [
                { text: "eApp", value: "eAppInd" },
                { text: "Paper App", value: "PaperAppInd" },
                { text: "Instant Decision", value: "InstantDecisionInd" },
                { text: "Flexible Premium Possible", value: "FlexiblePremiumPossibleInd" },
                { text: "NY", value: "NY" },
                { text: "CA", value: "CA" }
            ],
            other_filter_selections: [],
            other_filters: {}
        }
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data.filter(row => {

                const { Carrier, ProductSolution, ProductType, ProductSubtype } = this.filters
                if (Carrier != '-All-' && Carrier != row.CarrierID)
                    return false
                if (ProductSolution !== '-All-' && ProductSolution !== row.ProductSolution)
                    return false
                if (ProductType !== '-All-' && ProductType !== row.ProductType)
                    return false
                if (ProductSubtype !== '-All-' && ProductSubtype !== row.ProductSubtype)
                    return false

                const { eAppInd, PaperAppInd, InstantDecisionInd, FlexiblePremiumPossibleInd, CA, NY } = this.other_filters
                if (eAppInd == '1' && row.eAppInd != '1')
                    return false
                if (PaperAppInd == '1' && row.PaperAppInd != '1')
                    return false
                if (InstantDecisionInd == '1' && row.InstantDecisionInd != '1')
                    return false
                if (FlexiblePremiumPossibleInd == '1' && row.FlexiblePremiumPossibleInd != '1')
                    return false
                if (CA == '1' && row.CA != '1')
                    return false
                if (NY == '1' && row.NY != '1')
                    return false

                return true
            })
        },
        filter_keys: function() {
            return ['Carrier', 'ProductSolution', 'ProductType', 'ProductSubtype']; //this must sync up with availableFilters in the Model in Laravel. 
        },
        total_items: function() {
            return this.total_num;
        },
        visibleHeaders() {
            if (this.$vuetify.breakpoint.xs) {
                return this.the_headers.slice(0, 1)
            }

            if (this.$vuetify.breakpoint.smAndDown) {
                return this.the_headers.slice(0, 2)
            }

            if (this.$vuetify.breakpoint.mdAndDown) {
                return this.the_headers.slice(0, 3)
            }

            return []
        },
        the_headers: function() {
            return [{
                text: 'Product',
                value: 'Product',
                align: "left"
            }, {
                text: 'Carrier',
                value: 'carrier.CarrierName',
                align: "left"
            }, {
                text: 'Solution',
                value: 'ProductSolution',
                align: "left"
            }, {
                text: 'Type',
                value: 'ProductType',
                align: "left"
            }, {
                text: 'Sub Type',
                value: 'ProductSubtype',
                align: "left"
            }, {
                text: 'eApp',
                value: 'eAppInd',
                align: "center"
            }, {
                text: 'Paper App',
                value: 'PaperAppInd',
                align: "center"
            }, {
                text: 'Instant Decision',
                value: 'InstantDecisionInd',
                align: "center"
            }, {
                text: 'Flexible Premium Possible',
                value: 'FlexiblePremiumPossibleInd',
                align: "center"
            }, {
                text: 'NY',
                value: 'NY',
                align: "center"
            }, {
                text: 'CA',
                value: 'CA',
                align: "center"
            }, {
                text: 'Product Guide',
                value: 'ProductGuide',
                align: "center",
            }]
        },
        available_filters: function() {
            return this.available_product_filters;
        }
    },
    methods: {
        loadData: debounce(function(opt) {
            opt.itemsPerPage = 'all'
            if (this.the_data.length > 0)
                return

            var g = this
            this.data_loading = true
            const filters = { ...this.filters }
            //override
            filters.Carrier = '-All-'
            QuilityAPI.getProducts({ filters, ...this.other_filters }, opt).then(function(json) {
                g.data_loading = false
                if (typeof json.response != 'undefined' && json.response.clientError) {
                    g.showError(json.response.body.message);
                    return
                }
                let the_data = g.filterProductsByUserDivision(json.data)
                g.$set(g, 'the_data', the_data);
                g.total_num = json.meta.total
                if (typeof json.meta.filters != 'undefined') {
                    g.$set(g, 'available_product_filters', json.meta.filters)
                }
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                g.showError(err.message);
            });
            this.firstPull = false;
        }, 200),
        viewProduct: function(item) {
            this.$router.push({ name: 'ProductDetail', params: { product_id: item.ID } })
        },
        otherFiltersUpdated() {
            this.other_filters = {}

            let g = this
            if (this.other_filter_selections.length > 0) {
                this.other_filter_selections.map((key, val) => {
                    g.other_filters[key] = "1"
                })
            }
        },
    },
    components: {
        QExpandableDataTable,
    },
    activated() {
        if (localStorage.getItem("refreshProducts") == "true") {
            localStorage.setItem("refreshProducts", false)
            this.refreshData()
        }
    },
    watch: {
        'showFilters': function(newV, oldV) {
            if (newV === false) {
                this.other_filters = {}
                this.other_filter_selections = []
            }

        }
    }
}

</script>
<style scoped>
div>>>td,
div>>>th {
    white-space: nowrap;
    cursor: pointer;
}

</style>
