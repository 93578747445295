<template>
  <v-row class="d-flex justify-start" v-if="canViewDormantStatus">
		<v-col cols="12">
			<div class="d-flex px-2" style="gap: 1rem 1.5rem; flex-wrap: wrap;">
				<template v-for="data in dormantStatusData">
					<div
						v-if="dormantStatuses[data.key]"
						:key="data.key"
						:class="{ 'dormant-status--active': value === data.key }"
						class="dormant-status"
						@click="selectStatus(data.key)"
					>
						<span class="mr-2">{{ data.label }}</span>
						<v-tooltip top>
							<template v-slot:activator="{ on, attrs }">
								<v-icon color="#1570ef" v-bind="attrs" v-on="on">icon-info-circle-q</v-icon>
							</template>
							{{ data.tooltip }}
						</v-tooltip>
						<div v-if="!value || value === data.key">({{ dormantStatuses[data.key].count }})</div>
					</div>
				</template>
			</div>
		</v-col>
	</v-row>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'

const dormantStatusData = [
	{
		label: 'Active',
		key: 'Active',
		tooltip: 'Agent is actively logging in and out of both systems.',
	},
	{
		label: 'Dormant 15-30 Days',
		key: 'Dormant_15_30',
		tooltip: 'Agent has fallen into the “Available” status and has not logged into HQ/OPT.',
	},
	{
		label: 'Dormant 31-90 Days',
		key: 'Dormant_31_90',
		tooltip: 'Agent remains in “Available” status.',
	},
	{
		label: 'Dormant 91-180 Days',
		key: 'Dormant_91_180',
		tooltip: 'Agent remains in “Available” or “Stalled” status. (Agent falls into a “Stalled” status if they have an active downline). Home office must unlock agent in OPT.',
	},
	{
		label: 'Dormant 181-364 Days',
		key: 'Dormant_181_364',
		tooltip: 'Agent remains in “Available” or “Stalled” status (Agent falls into a “Stalled” status if they have an active downline). Home office must unlock agent in OPT and agent is in jeopardy of having to re-onboard.',
	},
	{
		label: 'Lapsed 365+',
		key: 'Lapsed',
		tooltip: 'Agent has been inactive for over 1 year (must be re-contracted through the home office).',
	},
	{
		label: 'Stalled',
		key: 'Stalled',
		tooltip: 'Agent is inactive but maintains an active downline.',
	},
]

export default {
    name: "QDormantStatuses",
	props: {
		value: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			dormantStatuses: {},
			dormantStatusData,
		};
	},
	computed: {
		canViewDormantStatus() {
			return this.hasRole(['SuperAdmin', 'Staff']) || this.user.Agent.HasManagerAccess;
		},
	},
	methods: {
		selectStatus(key) {
			this.$emit('input', this.value === key ? '' : key)
		},
	},
	watch: {
		canViewDormantStatus: {
			handler(newValue) {
				if (newValue) {
					QuilityAPI.getDormantStatus()
						.then((response) => {
							this.dormantStatuses = response.data
						});
				}
			},
			immediate: true,
		}
	},
}
</script>

<style lang="scss" scoped>
.dormant-status {
	border: 1px solid var(--app-color-neutral-1);
	padding: 0.75rem 1rem;
	color: var(--app-color-neutral-1);
	border-radius: 0.5rem;
	cursor: pointer;

	&--active {
		border: 1px solid #2f2f2f;
		padding: 0.75rem 1rem;
		color: #2f2f2f;
		font-weight: 600;
	}
}
</style>